export default defineNuxtRouteMiddleware(async (to) => {
  const submissionId = to.params?.submissionId;
  const submission = await useQuizSubmission().byId(submissionId as string);
  const group = await useGroup().byId(submission?.group_id as string);
  const school = await useSchool().byId(group?.team_id as string);

  if (
    !school ||
    !(await useSchool().hasLifestyleAccess(school?.id as string))
  ) {
    return navigateTo({ name: 'quiz.select' });
  }
});
